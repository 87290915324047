import React from "react";
import styled from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import { setIsPrivacyPolicy, setSignUpUser } from "../../slices/authSlice";
import { toast } from "react-toastify";
import FormFooter from "./FormFooter";
import FormHeader from "./FormHeader";
import FormInput from "./FormInput";
import colors from "../../constants/colors";

const TextContainer = styled.div`
  width: 90%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  color: ${colors.white};
  overflow: auto;
  padding: calc(1vw);
  font-size: calc(1.1vw);
`;

const SignUpForm = () => {
  const signUpUser = useSelector((state) => state.auth.signUpUser);
  const isPrivacyPolicy = useSelector((state) => state.auth.isPrivacyPolicy);
  const isTermsAndConditions = useSelector(
    (state) => state.auth.isTermsAndConditions
  );
  const dispatch = useDispatch();

  const handleInput = (value, name) => {
    const updatedUser = { ...signUpUser };

    updatedUser[name] = value;

    dispatch(setSignUpUser(updatedUser));
  };

  const handleSubmit = () => {
    if (signUpUser?.name && signUpUser?.email && signUpUser?.password) {
      dispatch(setIsPrivacyPolicy(true));
    } else {
      toast.error("Please complete the sign up form");
    }
  };

  return (
    <>
      {isPrivacyPolicy || isTermsAndConditions ? (
        <>
          <FormHeader
            title={isPrivacyPolicy ? "Privacy Policy" : "Terms of Use"}
          />
          <TextContainer>Need to provide what to put here</TextContainer>
          <FormFooter
            others={isPrivacyPolicy ? "privacy" : "t&c"}
            hideButton
            hideAction
          />
        </>
      ) : (
        <>
          <FormHeader title="Sign Up" />
          <FormInput
            top="18%"
            name="Name"
            value={signUpUser.name}
            onChange={(val) => handleInput(val, "name")}
          />
          <FormInput
            top="34%"
            name="Email"
            value={signUpUser.email}
            onChange={(val) => handleInput(val, "email")}
          />
          <FormInput
            top="50%"
            name="Password"
            type="password"
            value={signUpUser.password}
            onChange={(val) => handleInput(val, "password")}
          />
          <FormFooter onSubmit={handleSubmit} />
        </>
      )}
    </>
  );
};

export default SignUpForm;
