import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import {
  clearIsPasswordReset,
  setIsLogin,
  setIsPasswordResetRequest,
} from "../../slices/authSlice";
import ButtonStatic from "../../assets/images/form/buttonStatic.png";
import ButtonHover from "../../assets/images/form/buttonHover.png";
import colors from "../../constants/colors";

const ButtonWrap = styled.div`
  position: absolute;
  bottom: 10%;
  right: 7.5%;
  width: 50%;
  height: 10%;
  cursor: pointer;

  img.hover {
    display: none;
  }

  :hover {
    img.hover {
      display: block;
    }

    img.static {
      display: none;
    }
  }
`;

const ButtonBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const FormSwitchWrap = styled.div`
  color: ${colors.white};
  position: absolute;
  bottom: 10%;
  left: 10%;
  font-size: calc(1vw);
  width: 40%;
`;

const SwitchAction = styled.div`
  color: ${colors.white};
  text-decoration: underline;
  cursor: pointer;
`;

const FormFooter = ({
  onSubmit,
  hideAction,
  passwordResetRequest,
  passwordReset,
  hideButton,
}) => {
  const isLogin = useSelector((state) => state.auth.isLogin);
  const dispatch = useDispatch();

  return (
    <>
      {!hideAction && (
        <FormSwitchWrap>
          {isLogin ? "Don't have an account?" : "Already signed up?"}
          <SwitchAction onClick={() => dispatch(setIsLogin(!isLogin))}>
            {isLogin ? "Sign up here" : "Login here"}
          </SwitchAction>
        </FormSwitchWrap>
      )}
      {passwordResetRequest && (
        <FormSwitchWrap>
          Know your password?
          <SwitchAction
            onClick={() => dispatch(setIsPasswordResetRequest(false))}
          >
            Back to login
          </SwitchAction>
        </FormSwitchWrap>
      )}
      {passwordReset && (
        <FormSwitchWrap>
          Know your password?
          <SwitchAction onClick={() => dispatch(clearIsPasswordReset())}>
            Back to login
          </SwitchAction>
        </FormSwitchWrap>
      )}
      {!hideButton && (
        <ButtonWrap onClick={onSubmit}>
          <ButtonBackground className="static" src={ButtonStatic} alt="" />
          <ButtonBackground className="hover" src={ButtonHover} alt="" />
        </ButtonWrap>
      )}
    </>
  );
};

export default FormFooter;
