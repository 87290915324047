import React, { useState } from "react";
import styled from "styled-components/macro";
import Border from "../../assets/images/form/inputBorder.png";
import BorderFocus from "../../assets/images/form/inputBorderFocus.png";
import colors from "../../constants/colors";

const InputWrap = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: 49%;
  transform: translateX(-50%);
  height: 13.5%;
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const InputBorder = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
const InputBackground = styled.div`
  width: 97%;
  height: 80%;
  background: linear-gradient(
    170deg,
    rgba(185, 185, 185, 0.4) 50%,
    rgba(255, 255, 255, 0.4) 50%
  );
`;
const FieldWrap = styled.div`
  width: 96%;
  height: 50%;
  position: absolute;
  bottom: 12%;
  left: 50%;
  transform: translateX(-50%);
`;
const Input = styled.input`
  display: block;
  height: 100%;
  width: 95%;
  margin: 0 auto;
  border: none;
  outline: none;
  background: transparent;
  color: ${colors.white};
  font-size: calc(1.2vw);
`;
const Label = styled.div`
  position: absolute;
  top: ${(props) => (props.isFocused ? "-35%" : "0")};
  left: 3%;
  font-size: calc(${(props) => (props.isFocused ? "1vw" : "1.15vw")});
  transform: translateY(-25%);
  text-transform: uppercase;
  color: ${colors.textBlue};
  transition: all 0.2s ease-in-out;
`;

const FormInput = ({ top, name, type, value, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <InputWrap top={top}>
      <InputBorder src={isFocused ? BorderFocus : Border} alt="" />
      <InputBackground />
      <FieldWrap>
        <Input
          type={type || "text"}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <Label isFocused={isFocused || value}>{name}</Label>
      </FieldWrap>
    </InputWrap>
  );
};

export default FormInput;
