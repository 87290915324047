import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

import BorderBg from "../assets/images/form/declarationBorder.png";
import AgreeHover from "../assets/images/form/agreeHover.png";
import AgreeNoHover from "../assets/images/form/agreeNoHover.png";
import DisagreeHover from "../assets/images/form/disagreeHover.png";
import DisagreeNoHover from "../assets/images/form/disagreeNoHover.png";
import CheckboxStatic from "../assets/images/form/checkBoxNormal.png";
import CheckboxChecked from "../assets/images/form/checkBoxChecked.png";

import colors from "../constants/colors";

import {
  resetSignUp,
  setAcceptedTerms,
  setIsLogin,
  setIsPrivacyPolicy,
} from "../slices/authSlice";

import { createUser } from "../services/axios/auth";

const Container = styled(motion.div)`
  background-color: ${colors.declarationBox};
  backdrop-filter: blur(16px);

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: ${(props) => (props.isTerms ? "82%" : "60%")};
  height: ${(props) => (props.isTerms ? "100%" : "73%")};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in-out;
`;

const BGImage = styled.img`
  width: 101%;
  margin-left: -0.5%;
  margin-top: -0.5%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  padding: calc(2vw) calc(5vw);
`;

const Title = styled.div`
  display: block;
  margin: 0 auto;
  color: ${colors.textBlue};
  text-shadow: 0px 0px 15px ${colors.textShadowBlue};
  font-size: calc(1.75vw);
  font-weight: bold;
  text-align: center;
`;

const DisclaimerWrap = styled.div`
  margin-top: calc(2vw);
`;

const ContentText = styled.div`
  font-size: calc(1vw);
  margin-top: calc(2vw);
  color: ${colors.white};
  line-height: calc(1.75vw);
`;

const ClickableText = styled.a`
  cursor: pointer;
  color: ${colors.white};
  text-decoration: underline;

  :hover {
    opacity: 0.75;
  }
`;

const CheckboxWrap = styled.div`
  width: 90%;
  height: 5%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  margin-top: calc(2vw);
`;

const Checkbox = styled.img`
  width: 4%;
  cursor: pointer;
`;

const CheckboxText = styled.div`
  flex: 1;
  font-size: calc(1vw);
  color: ${colors.white};
  margin-left: calc(1vw);
`;

const ButtonGroup = styled.div`
  margin-top: calc(1.5vw);
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrap = styled.div`
  width: 30%;
  height: 80%;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  position: relative;
  opacity: ${(props) => props.disabled && 0.5};

  img.hover {
    display: none;
  }

  :hover {
    img.hover {
      display: block;
    }

    img.static {
      display: none;
    }
  }
`;

const ButtonBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const Spacer = styled.div`
  width: calc(5vw);
`;

const PrivacyPolicy = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const acceptedTerms = useSelector((state) => state?.auth?.acceptedTerms);
  const signUpUser = useSelector((state) => state?.auth?.signUpUser);

  const handleDisagree = () => {
    if (isSubmitting) return;
    dispatch(setIsPrivacyPolicy(false));
  };

  const handleAgree = async () => {
    if (isSubmitting) return;
    if (!acceptedTerms) {
      toast.error(`Please accept the T&C's`);
    } else {
      setIsSubmitting(true);
      const response = await createUser(signUpUser);

      if (response.isSuccessful) {
        dispatch(resetSignUp());
        dispatch(setIsLogin(true));
        setIsSubmitting(false);
      } else {
        response.errors.forEach((err) => {
          toast.error(err);
          dispatch(setIsPrivacyPolicy(false));
          setIsSubmitting(false);
        });
      }
    }
  };

  return (
    <Container>
      <BGImage src={BorderBg} />
      <Content>
        <>
          <Title>Declaration</Title>
          <DisclaimerWrap>
            <ContentText>
              You are advised to read the disclaimer and terms & conditions
              before accessing this page.
            </ContentText>
            <ContentText>
              No information set out or referred to in this website and any
              information downloaded therefrom shall form the basis of any
              contract. All information contained in the website shall not be
              deemed to be any form of binding oﬀer or commitment on the part of
              Ericsenz Capital Pte. Ltd. (“Ericsenz Capital”). This website and
              all information herein is provided for use by the yourself as the
              intended recipient for information purposes only. The website and
              the information contained therein is prepared for the consumption
              and use of Accredited/Institutional investors (and any such
              equivalent class of investors) with a high degree of ﬁnancial
              markets understanding and knowledge. This website and any of its
              information is not intended for use by private or retail investors
              in Singapore or any other jurisdiction.
            </ContentText>
            <ContentText>
              By accessing this website, you agree to be bound by the following{" "}
              <ClickableText
                href="https://frontier-z-assets.s3.ap-southeast-1.amazonaws.com/documents/Full+Terms+Of+Use.pdf"
                target="_blank"
                rel="norefferer noopener"
              >
                terms & conditions.
              </ClickableText>
            </ContentText>
          </DisclaimerWrap>
          <CheckboxWrap>
            <Checkbox
              src={acceptedTerms ? CheckboxChecked : CheckboxStatic}
              alt=""
              onClick={() => dispatch(setAcceptedTerms(!acceptedTerms))}
            />
            <CheckboxText>
              I have read and understood the disclaimer, terms and conditions as
              stated in the link
            </CheckboxText>
          </CheckboxWrap>
          <ButtonGroup>
            <ButtonWrap disabled={isSubmitting} onClick={handleAgree}>
              <ButtonBackground className="static" src={AgreeNoHover} alt="" />
              <ButtonBackground className="hover" src={AgreeHover} alt="" />
            </ButtonWrap>
            <Spacer />
            <ButtonWrap disabled={isSubmitting} onClick={handleDisagree}>
              <ButtonBackground
                className="static"
                src={DisagreeNoHover}
                alt=""
              />
              <ButtonBackground className="hover" src={DisagreeHover} alt="" />
            </ButtonWrap>
          </ButtonGroup>
        </>
      </Content>
    </Container>
  );
};

export default PrivacyPolicy;
