import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AICompanyPage,
  AICybersecurity,
  DigitalZone,
  DZCompanyPage,
  Home,
  Metaverse,
  MVCompanyPage,
  SFCompanyPage,
  SpaceFrontier,
} from "./pages";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EscKeyHandler from "./services/EscKeyHandler";
import PostMessageHandler from "./services/PostMessageHandler";
import OrientationHandler from "./services/OrientationHandler";
import PreCache from "./services/PreCache";
import Amplitude from "./services/amplitudeHelper";
import "./animate.css";
import Lobby from "./components/Lobby";
import SurveyFormModal from "./components/SurveyFormModal";
import IntroVideoModal from "./components/IntroVideoModal";
import BackgroundAudio from "./components/BackgroundAudio";
import Authenticator from "./services/Authenticator";
import { setIsPasswordReset } from "./slices/authSlice";

const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    Amplitude.init();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && params.redirect_from) {
      sessionStorage.setItem("redirect", params.redirect_from);
      history.push(`/`);
    } else if (params && params.token) {
      dispatch(setIsPasswordReset(params.token));
      localStorage.setItem("rpToken", params.token);
      history.push(`/`);
    } else {
      history.push("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <OrientationHandler>
      <ToastContainer
        theme="dark"
        position="top-center"
        autoClose={3000}
        transition={Slide}
      />
      <Authenticator>
        <PreCache>
          <Lobby />
          <BackgroundAudio />
          <SurveyFormModal />
          <IntroVideoModal />
          <PostMessageHandler>
            <EscKeyHandler>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/space-frontier" component={SpaceFrontier} />
                <Route exact path="/metaverse" component={Metaverse} />
                <Route exact path="/digital-zone" component={DigitalZone} />
                <Route
                  exact
                  path="/ai-cybersecurity"
                  component={AICybersecurity}
                />
                <Route
                  path="/space-frontier/:company"
                  component={SFCompanyPage}
                />
                <Route path="/metaverse/:company" component={MVCompanyPage} />
                <Route
                  path="/digital-zone/:company"
                  component={DZCompanyPage}
                />
                <Route
                  path="/ai-cybersecurity/:company"
                  component={AICompanyPage}
                />
              </Switch>
            </EscKeyHandler>
          </PostMessageHandler>
        </PreCache>
      </Authenticator>
    </OrientationHandler>
  );
};

export default App;
