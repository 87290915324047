import React from "react";
import styled from "styled-components/macro";
import Border from "../../assets/images/form/headerBorder.png";
import colors from "../../constants/colors";

const HeaderWrap = styled.div`
  position: absolute;
  top: 4%;
  left: 49%;
  transform: translateX(-50%);
  width: 85%;
`;
const HeaderBorder = styled.img`
  width: 100%;
`;
const HeaderText = styled.div`
  color: ${colors.textBlue};
  text-shadow: 0px 0px 15px ${colors.textShadowBlue};
  font-size: calc(1.5vw);
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  top: 45%;
  left: 5%;
  transform: translateY(-50%);
`;

const FormHeader = ({ title }) => {
  return (
    <HeaderWrap>
      <HeaderBorder src={Border} alt="" />
      <HeaderText>{title}</HeaderText>
    </HeaderWrap>
  );
};

export default FormHeader;
