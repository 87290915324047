import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import Loader from "react-loader-spinner";
import Authentication from "../components/Authentication";
import { validateToken } from "../services/axios/auth";
import { setIsAuthenticated } from "../slices/authSlice";
import Amplitude from "./amplitudeHelper";

const LoaderWrap = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Authenticator = ({ children }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    const validateCookie = async (token) => {
      const response = await validateToken(token);

      if (response && response.isSuccessful) {
        setTimeout(() => {
          const email = localStorage.getItem("frontier-z-session-user");
          Amplitude.setUser(email);
          setIsAuthenticating(false);
          dispatch(setIsAuthenticated(true));
        }, 1000);
      } else {
        setTimeout(() => {
          setIsAuthenticating(false);
          dispatch(setIsAuthenticated(false));
        }, 1000);
      }
    };
    const token = sessionStorage.getItem("sessionToken");

    if (token) {
      validateCookie(token);
    } else {
      setIsAuthenticating(false);
    }
  }, [dispatch]);

  return (
    <>
      {!isAuthenticated ? (
        <>
          {isAuthenticating ? (
            <LoaderWrap>
              <Loader type="Circles" height={50} color="#63acff" />
            </LoaderWrap>
          ) : (
            <Authentication />
          )}
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Authenticator;
