import axios from "axios";

export const validateToken = async (token) => {
  try {
    const url = `${process.env.REACT_APP_SERVER_PATH}/private/v1/sessions/validate`;
    const headers = {
      Authentication: `Bearer ${token}`,
    };
    const response = await axios.get(url, headers);

    if (response && response.status === 200) {
      return { isSuccessful: true };
    } else {
      return { isSuccessful: false };
    }
  } catch (error) {
    return { isSuccessful: false };
  }
};

export const createUser = async (user) => {
  try {
    const url = `${process.env.REACT_APP_SERVER_PATH}/private/v1/users`;
    const response = await axios.post(url, user);

    if (response && response.status === 200) {
      if (response.data && response.data.success) {
        return { isSuccessful: true, message: "Account created, please login" };
      } else {
        return { isSuccessful: false, errors: response.data.errors };
      }
    } else {
      return {
        isSuccessful: false,
        errors: ["Something went wrong, please try again"],
      };
    }
  } catch (error) {
    return {
      isSuccessful: false,
      errors: ["Something went wrong, please try again"],
    };
  }
};

export const createUserSession = async (user) => {
  try {
    const url = `${process.env.REACT_APP_SERVER_PATH}/private/v1/sessions`;
    const response = await axios.post(url, { user });

    if (response && response.status === 200) {
      return {
        isSuccessful: true,
        message: "Logged in successfully",
        email: response?.data?.email,
        token: response?.data?.token,
      };
    } else {
      return {
        isSuccessful: false,
        errors: ["Unable to login, please try again"],
      };
    }
  } catch (error) {
    return {
      isSuccessful: false,
      errors: [error.response.data.error],
    };
  }
};

export const requestPasswordReset = async (email) => {
  try {
    const url = `${process.env.REACT_APP_SERVER_PATH}/private/v1/users/forgot_password`;
    const response = await axios.post(url, { email });

    if (response && response.status === 200) {
      if (response.data && response.data.success) {
        return {
          isSuccessful: true,
          message: response.data && response.data.message,
        };
      } else {
        return {
          isSuccessful: false,
          message: response.data && response.data.message,
        };
      }
    } else {
      return {
        isSuccessful: false,
        message: "Something went wrong, please try again",
      };
    }
  } catch (error) {
    return {
      isSuccessful: false,
      message: "Something went wrong, please try again",
    };
  }
};

export const validateResetPasswordToken = async (token) => {
  try {
    const url = `${process.env.REACT_APP_SERVER_PATH}/private/v1/users/reset_password/validate?token=${token}`;
    const response = await axios.get(url);

    if (response?.status === 200) {
      if (response?.data?.valid) {
        return { valid: true, message: response?.data?.message };
      } else {
        return { valid: false, message: response?.data?.message };
      }
    } else {
      return {
        valid: false,
        message: "Something went wrong, please retry the link",
      };
    }
  } catch (error) {
    return {
      valid: false,
      message: "Something went wrong, please retry the link",
    };
  }
};

export const resetUserPassword = async (password, confirmPassword, token) => {
  try {
    const url = `${process.env.REACT_APP_SERVER_PATH}/private/v1/users/reset_password`;
    const response = await axios.post(url, {
      token,
      password,
      confirm_password: confirmPassword,
    });

    if (response?.status === 200 && response?.data?.success) {
      return { isSuccessful: true, message: response?.data?.message };
    } else {
      return {
        isSuccessful: false,
        errors: ["Something went wrong, please try again"],
      };
    }
  } catch (error) {
    return {
      isSuccessful: false,
      errors: ["Something went wrong, please try again"],
    };
  }
};
