import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openCloseLearnMore } from "../slices/modalSlice";
import { stopAudio, playAudio } from "../slices/backgroundAudioSlice";
import styled from "styled-components/macro";
import { CloseOutline } from "@styled-icons/evaicons-outline";

import Play from "../assets/images/icons/playVideo.png";

import breakpoints from "../constants/breakpoints";
import colors from "../constants/colors";
import Amplitude from "../services/amplitudeHelper";
import useWindowSize from "../hooks/useWindowSize";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  height: ${(props) => `${props.height}px`};
  width: 100vw;
  background-color: ${colors.overlay};
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  padding: 0;
`;

const BackgroundWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transform: scale(${(props) => (props.open ? 1 : 0)});
  transition: transform 0.3s ease-in-out;
`;

const StyledCloseIcon = styled(CloseOutline)`
  width: calc(3vw);
  height: calc(3vw);
  cursor: pointer;
  position: absolute;
  top: 2rem;
  left: 2rem;
  color: ${colors.white};
  z-index: 999;
`;

const VideoContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  height: 90%;
  margin: 1rem 0;
`;

const VideoPlayer = styled.video`
  height: 100%;
  display: block;
  margin: 0 auto;
  background-color: black;

  @media screen and (min-width: ${breakpoints.lg}) {
    width: 80%;
  }
`;

const PlayButton = styled.img`
  width: 15%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

const IntroVideoModal = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const size = useWindowSize();
  const videoRef = useRef(null);
  const isLearnMoreOpen = useSelector((state) => state.modal.isLearnMoreOpen);
  const dispatch = useDispatch();
  const videoURL = useSelector(
    (state) => state?.app?.siteData?.lobby_video_url
  );
  const posterURL = useSelector(
    (state) => state?.app?.siteData?.lobby_video_thumbnail_url
  );

  const handleClose = () => {
    setIsVideoPlaying(false);
    videoRef.current.pause();
    dispatch(openCloseLearnMore(false));
  };

  const startVideo = () => {
    Amplitude.clickWatchVideo("Home", "Learn More");
    setIsVideoPlaying(true);
    dispatch(stopAudio());
    videoRef.current.play();
  };

  const videoEnded = () => {
    dispatch(playAudio());
    setIsVideoPlaying(false);
  };

  const videoPlay = () => {
    dispatch(stopAudio());
  };

  const videoPaused = () => {
    dispatch(playAudio());
  };

  return (
    <Overlay open={isLearnMoreOpen} height={size.height}>
      <StyledCloseIcon onClick={() => handleClose()} size="24px" />
      <BackgroundWrap open={isLearnMoreOpen}>
        <VideoContainer>
          {!isVideoPlaying && <PlayButton src={Play} onClick={startVideo} />}
          <VideoPlayer
            ref={videoRef}
            src={videoURL}
            type="video/mp4"
            poster={posterURL}
            controls={isVideoPlaying}
            onPause={videoPaused}
            onEnded={videoEnded}
            onPlay={videoPlay}
          />
        </VideoContainer>
      </BackgroundWrap>
    </Overlay>
  );
};

export default IntroVideoModal;
