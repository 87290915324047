import React from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";

import BorderSuccess from "../assets/images/form/borderSuccess.png";
import LoginHover from "../assets/images/form/loginHover.png";
import LoginNoHover from "../assets/images/form/loginNoHover.png";

import colors from "../constants/colors";

import { setIsSuccessfulSignUp } from "../slices/authSlice";

const Container = styled(motion.div)`
  background-color: ${colors.declarationBox};
  backdrop-filter: blur(16px);

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 60%;
  height: 37%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in-out;
`;

const BGImage = styled.img`
  width: 101%;
  margin-left: -0.5%;
  margin-top: -0.5%;
`;

const Title = styled.div`
  display: block;
  margin: 0 auto;
  color: ${colors.textBlue};
  text-shadow: 0px 0px 15px ${colors.textShadowBlue};
  font-size: calc(1.75vw);
  font-weight: bold;
  text-align: center;
`;

const ContentText = styled.div`
  text-align: center;
  font-size: calc(1.1vw);
  color: ${colors.white};
  line-height: calc(1.75vw);
`;

const ButtonWrap = styled.div`
  width: 25%;
  height: 20%;
  cursor: pointer;
  position: relative;

  img.hover {
    display: none;
  }

  :hover {
    img.hover {
      display: block;
    }

    img.static {
      display: none;
    }
  }
`;

const ButtonBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  padding: calc(2vw) calc(5vw);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const SuccessfulSignUp = () => {
  const dispatch = useDispatch();

  const handleGoToLogin = () => {
    dispatch(setIsSuccessfulSignUp(false));
  };

  return (
    <Container>
      <BGImage src={BorderSuccess} />
      <Content>
        <Title>REMINDER</Title>
        <ContentText>
          Thank you for registering! A confirmation email has been sent to your
          mailbox.
          <br />
          You may now Sign-In using your registered account.
        </ContentText>
        <ButtonWrap onClick={handleGoToLogin}>
          <ButtonBackground className="static" src={LoginNoHover} alt="" />
          <ButtonBackground className="hover" src={LoginHover} alt="" />
        </ButtonWrap>
      </Content>
    </Container>
  );
};

export default SuccessfulSignUp;
