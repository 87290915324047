import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components/macro";
import RegistrationBg from "../assets/images/backgroundImages/registration.jpeg";
import BackgroundImage from "./BackgroundImage";
import BorderBg from "../assets/images/form/border.png";
import SixteenByNineContainer from "./SixteenByNineContainer";
import PrivacyPolicy from "./PrivacyPolicy";
import { useSelector } from "react-redux";
import { LoginForm, SignUpForm } from "./forms";
import SuccessfulSignUp from "./SuccessfulSignUp";

const FormWrap = styled(motion.div)`
  background-image: url(${BorderBg});

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 40%;
  height: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Authentication = () => {
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const isSuccessfulSignUp = useSelector(
    (state) => state?.auth?.isSuccessfulSignUp
  );
  const isPrivacyPolicy = useSelector((state) => state?.auth?.isPrivacyPolicy);

  if (isSuccessfulSignUp) {
    return (
      <SixteenByNineContainer hideMute>
        <BackgroundImage image={RegistrationBg} />
        <SuccessfulSignUp />
      </SixteenByNineContainer>
    );
  }

  return (
    <SixteenByNineContainer hideMute>
      <BackgroundImage image={RegistrationBg} />
      {isPrivacyPolicy ? (
        <PrivacyPolicy />
      ) : (
        <FormWrap>{isLogin ? <LoginForm /> : <SignUpForm />}</FormWrap>
      )}
    </SixteenByNineContainer>
  );
};

export default Authentication;
