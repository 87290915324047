import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  createUserSession,
  requestPasswordReset,
  resetUserPassword,
  validateResetPasswordToken,
} from "../../services/axios/auth";
import {
  clearIsPasswordReset,
  resetLogin,
  setIsAuthenticated,
  setIsPasswordResetRequest,
  setLoginUser,
  setPasswordReset,
} from "../../slices/authSlice";
import FormFooter from "./FormFooter";
import FormHeader from "./FormHeader";
import FormInput from "./FormInput";
import colors from "../../constants/colors";
import Amplitude from "../../services/amplitudeHelper";

const ForgotPasswordWrap = styled.div`
  width: 90%;
  position: absolute;
  top: ${(props) => props.top};
  left: 55%;
  transform: translateX(-50%);
  font-size: calc(0.85vw);
  color: ${colors.white};
`;

const ClickableText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const LoginForm = () => {
  const loginUser = useSelector((state) => state.auth.loginUser);
  const passwordReset = useSelector((state) => state.auth.passwordReset);
  const resetToken = useSelector((state) => state.auth.resetToken);
  const isPasswordResetRequest = useSelector(
    (state) => state.auth.isPasswordResetRequest
  );
  const isPasswordReset = useSelector((state) => state.auth.isPasswordReset);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkToken = async () => {
      const response = await validateResetPasswordToken(resetToken);

      if (!response?.valid) {
        dispatch(clearIsPasswordReset());
        toast.error(response.message);
      }
    };
    if (resetToken) {
      checkToken();
    }
  }, [resetToken, dispatch]);

  const handleInput = (value, name) => {
    const updatedUser = { ...loginUser };

    updatedUser[name] = value;

    dispatch(setLoginUser(updatedUser));
  };

  const handleResetPasswordInput = (value, name) => {
    const updatedPassword = { ...passwordReset };

    updatedPassword[name] = value;

    dispatch(setPasswordReset(updatedPassword));
  };

  const handleSubmit = async () => {
    const response = await createUserSession(loginUser);

    if (response.isSuccessful) {
      localStorage.setItem("frontier-z-session-user", response?.email);
      sessionStorage.setItem("sessionToken", response?.token);
      Amplitude.setUser(response?.email);
      toast.success(response.message);
      dispatch(resetLogin());
      dispatch(setIsAuthenticated(true));
    } else {
      response.errors.forEach((err) => {
        toast.error(err);
      });
    }
  };

  const handleResetPasswordRequest = async () => {
    if (loginUser && loginUser.email) {
      const response = await requestPasswordReset(loginUser.email);
      if (response.isSuccessful) {
        toast.success(response.message);
        dispatch(resetLogin());
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Please provide an email address");
    }
  };

  const handleResetPassword = async () => {
    if (passwordReset?.password === passwordReset?.confirmPassword) {
      const response = await resetUserPassword(
        passwordReset.password,
        passwordReset.password,
        resetToken
      );

      if (response?.isSuccessful) {
        dispatch(clearIsPasswordReset());
        toast.success(response.message);
      } else {
        response.errors.forEach((error) => {
          toast.error(error);
        });
      }
    } else {
      toast.error("Passwords do not match");
    }
  };

  return (
    <>
      {isPasswordResetRequest ? (
        <>
          <FormHeader title="Reset Password" />
          {isPasswordReset ? (
            <>
              <FormInput
                top="18%"
                name="Password"
                type="password"
                value={passwordReset.password}
                onChange={(val) => handleResetPasswordInput(val, "password")}
              />
              <FormInput
                top="34%"
                name="Confirm Password"
                type="password"
                value={passwordReset.confirmPassword}
                onChange={(val) =>
                  handleResetPasswordInput(val, "confirmPassword")
                }
              />
              <FormFooter
                onSubmit={handleResetPassword}
                hideAction
                passwordReset
              />
            </>
          ) : (
            <>
              <FormInput
                top="18%"
                name="Email"
                value={loginUser.email}
                onChange={(val) => handleInput(val, "email")}
              />
              <ForgotPasswordWrap top="35%">
                Enter your email to receive reset password instructions.
              </ForgotPasswordWrap>
              <FormFooter
                onSubmit={handleResetPasswordRequest}
                hideAction
                passwordResetRequest
              />
            </>
          )}
        </>
      ) : (
        <>
          <FormHeader title="Login Here" />
          <FormInput
            top="18%"
            name="Email"
            value={loginUser.email}
            onChange={(val) => handleInput(val, "email")}
          />
          <FormInput
            top="34%"
            name="Password"
            type="password"
            value={loginUser.password}
            onChange={(val) => handleInput(val, "password")}
          />
          <ForgotPasswordWrap top="50%">
            Forget Password?{" "}
            <ClickableText
              onClick={() => dispatch(setIsPasswordResetRequest(true))}
            >
              Click here
            </ClickableText>
          </ForgotPasswordWrap>
          <FormFooter onSubmit={handleSubmit} />
        </>
      )}
    </>
  );
};

export default LoginForm;
